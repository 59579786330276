import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal";
import "./project.css";
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import { Link } from "gatsby";
import styled from "styled-components";

//Styles
const ProContainer = styled.div`
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
		Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
	font-weight: bold;
	font-size: 100px;
	padding: 40px 25px 40px 25px;
	border: 10px solid ${props => props.color};
	position: relative;

	@media screen and (min-width: 480px) {
		padding-left: calc(10vmax);
		padding-right: calc(10vmin);
	}

	@media screen and (min-width: 680px) {
		padding-left: calc(10vmax);
		padding-right: calc(10vmin);
	}

	@media screen and (min-width: 890px) {
		padding-left: calc(15vmax);
		padding-right: calc(15vmax);
		padding-top: 50px;
	}

	@media screen and (min-width: 980px) {
		padding-left: calc(15vmax);
		padding-right: calc(15vmax);
		padding-top: 120px;
	}
`;

const ProjectTitle = styled.span`
	color: ${props => props.color};
	font-size: 50px;
	display: flex;
	margin-top: 45px;

	@media screen and (min-width: 480px) {
		font-size: 60px;
	}

	@media screen and (min-width: 680px) {
		font-size: 70px;
	}

	@media screen and (min-width: 890px) {
		font-size: 85px;
	}

	@media screen and (min-width: 980px) {
		font-size: 95px;
	}
`;

class Project extends Component {
	constructor({ props, pageContext }) {
		super(props);
		let {
			name,
			title,
			primaryColor,
			context,
			period,
			img,
			desc,
			role
		} = pageContext.project;
		this.state = {
			name,
			title,
			primaryColor,
			context,
			period,
			img,
			role,
			desc
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		ReactGA.pageview(`/projects/${this.state.title}`);
	}

	render() {
		const renderConsecutiveImages = imgArr => {
			return imgArr.slice(1).map((item, i) => {
				return (
					<React.Fragment key={i}>
						<Slide bottom cascade duration={50} distance="100px">
							<div className="project-image">
								<img src={item} width="100%" height="auto" alt="Timeline" />
							</div>
						</Slide>
					</React.Fragment>
				);
			});
		};

		return (
			<ProContainer>
				<Helmet>
					<title>Saransh Barua | Work</title>
				</Helmet>
				<Link to="/">
					<Fade delay={1000}>
						<div className="home-link">HOME</div>
					</Fade>
				</Link>
				<Fade bottom duration={500} distance="20px">
					<ProjectTitle color={this.state.primaryColor}>
						{this.state.title}
					</ProjectTitle>
				</Fade>
				<div className="project-details">
					<div style={{ display: "flex" }}>
						<Fade bottom cascade duration={700} delay={100}>
							<p className="lh-lg">
								<span className="small-headings">CONTEXT</span>
								<br />
								<span className="small-desc">{this.state.context}</span>
							</p>
						</Fade>
						<Fade bottom cascade delay={200}>
							<p className="small-margin-left lh-lg">
								<span className="small-headings">ROLE</span>
								<br />
								<span className="small-desc">{this.state.role}</span>
							</p>
						</Fade>
					</div>
					<Fade bottom cascade delay={300}>
						<p className="small-margin-left-lg lh-lg">
							<span className="small-headings">PERIOD</span>
							<br />
							<span className="small-desc">{this.state.period}</span>
						</p>
					</Fade>
				</div>
				<div className="project-image">
					<img
						src={this.state.img[0]}
						width="100%"
						height="auto"
						alt="Timeline"
					/>
				</div>
				<div className="project-summary">
					<Fade bottom cascade delay={400}>
						<p>
							<span className="small-headings">SUMMARY</span>
							<br />
							<span className="small-desc">{this.state.desc}</span>
						</p>
					</Fade>
				</div>
				{renderConsecutiveImages(this.state.img)}
			</ProContainer>
		);
	}
}

export default Project;
